<template>
  <div>
    <memberLevel :corpId="corpId"></memberLevel>
  </div>
</template>

<script>
import memberLevel from '@/views/frm/corp/memberGrade/index.vue'
export default {
  components: {
    memberLevel
  },
  data() {
    return {
      corpId: null
    }
  },
  created() {
    this.corpId = this.getCorpId()
  }
}
</script>

<style>
</style>