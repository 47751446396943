<!-- 详情弹窗 -->
<template>
  <a-modal
    title="详情"
    :width="580"
    :footer="null"
    :visible="visible"
    @update:visible="updateVisible"
  >
    <a-form
      class="ud-form-detail"
      :label-col="{ sm: { span: 5 }, xs: { span: 6 } }"
      :wrapper-col="{ sm: { span: 19 }, xs: { span: 18 } }"
    >
      <a-row :gutter="16">
        <a-col :sm="24" :xs="24">
          <!-- 等级名称 -->
          <a-form-item label="等级名称:">
            <div class="ud-text-secondary">
              {{ data.name }}
            </div>
          </a-form-item>
        </a-col>
        <a-col :sm="24" :xs="24">
          <!-- 等级权重 -->
          <a-form-item label="等级权重:">
            <div class="ud-text-secondary">
              {{ data.weight }}
            </div>
          </a-form-item>
        </a-col>
        <a-col :sm="24" :xs="24">
          <!-- 升级条件 -->
          <a-form-item label="升级条件:">
            <div class="ud-text-secondary">
              {{ data.upgrade }}元
            </div>
          </a-form-item>
        </a-col>
        <a-col :sm="24" :xs="24">
          <!-- 等级权益（折） -->
          <a-form-item label="等级权益（折）:">
            <div class="ud-text-secondary">
              {{ data.equity }}
            </div>
          </a-form-item>
        </a-col>
        <a-col :sm="24" :xs="24">
          <!-- 状态 -->
          <a-form-item label="状态:">
            <div class="ud-text-secondary" v-if="data.status === 0" >
              禁用
            </div>
            <div class="ud-text-secondary" v-if="data.status === 1">
              启用
            </div>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
  </a-modal>
</template>

<script>
export default {
  name: 'cardDetail',
  emits: ['update:visible'],
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 数据
    data: Object
  },
  data() {
    return {}
  },
  watch: {
    data() {
      if (this.data) {
        // console.log('data111', this.data)
        // this.data.map((item) => {
        //   if (item.status === 0) {
        //     item.statusName = '禁用'
        //   } else if (item.status === 1) {
        //     item.statusName = '启用'
        //   }
        // })
      }
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      // this.data.map((item) => {
      //   if (item.status === 0) {
      //     item.statusName = '禁用'
      //   } else if (item.status === 1) {
      //     item.statusName = '启用'
      //   }
      // })
    },
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value)
    }
  }
}
</script>

<style scoped>
.answer {
  padding-left: 5%;
}
</style>
